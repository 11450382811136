import { createSelector } from 'reselect';

import { UserState } from './user.reducer';

const selectStateUser = (state: any) => state.user;

export const selectToken = createSelector(
  // @ts-ignore
  [selectStateUser],
  (user: UserState) => user.token
);

export const selectUser = createSelector(
  // @ts-ignore
  [selectStateUser],
  (user: UserState) => user.user
);

export const selectSchoolCode = createSelector(
  // @ts-ignore
  [selectStateUser],
  (user: UserState) => user.school_code
);

export const selectUserLoadingAndError = createSelector(
  [selectStateUser],
  (user: UserState) => ({ error: user.error, loadingUser: user.loadingUser })
);

export const selectUserTokenAndType = createSelector(
  [selectStateUser],
  (user: UserState) => ({ token: user.token, type: user.type })
);

export const selectUserTheme = createSelector(
  [selectStateUser],
  (user: UserState) => ({ theme: user.theme })
);
