import { lazy } from 'react';

const publicRoutes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('./Cupom/')),
  },

  {
    path: '/login',
    exact: true,
    component: lazy(() => import('./Login/')),
  },
  {
    path: '/TermsAndPolicy',
    exact: true,
    component: lazy(() => import('./TermsAndPolicy/')),
  },
  {
    path: '/SignUp',
    exact: true,
    component: lazy(() => import('./SignUp/')),
  },

  //temporariamente publica, precisa mudar para private
  {
    path: '/esqueci-senha',
    exact: true,
    component: lazy(() => import('./ForgotPassword/')),
  },
  {
    path: '/esqueci-senha-codigo',
    exact: true,
    component: lazy(() => import('./ForgotPasswordCode/')),
  },
  {
    path: '/recuperar-senha',
    exact: true,
    component: lazy(() => import('./ResetPassword/')),
  },
  {
    path: '/turmas-disponiveis',
    exact: true,
    component: lazy(() => import('./AvailableCourses/')),
  },
  {
    path: '/turmas-matriculadas',
    exact: true,
    component: lazy(() => import('./SignedCourses/')),
  },

  {
    path: '/detalhes-classe',
    exact: true,
    component: lazy(() => import('./ClassDetail/')),
  },
  {
    path: '/detalhes-classe-assinada',
    exact: true,
    component: lazy(() => import('./SignedClassDetail/')),
  },
  {
    path: '/editar-classe-assinada',
    exact: true,
    component: lazy(() => import('./SignedClassDetailEdit/')),
  },
  {
    path: '/assinar-classe',
    exact: true,
    component: lazy(() => import('./SubscribeClass/')),
  },
  {
    path: '/historico-pagamento',
    exact: true,
    component: lazy(() => import('./PaymentHistory/')),
  },
  {
    path: '/metodo-pagamento',
    exact: true,
    component: lazy(() => import('./PaymentMethod/')),
  },
  {
    path: '/editar-matricula',
    exact: true,
    component: lazy(() => import('./CoursesToEdit/')),
  },
];

export default publicRoutes;
