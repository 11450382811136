import { RESPONSIVE_VALUE } from './../../config/constants';
import styled from 'styled-components';
import {
  font,
  headerLinkColor,
  secondaryColor,
} from './../../config/theme/index';

const RESPONSIVE = '1030px';

export const Container = styled.header`
  width: 100%;
  max-width: 100%;
  height: 120px;
  padding: 30px;
  background-color: #0d1d36;

  @media (max-width: ${RESPONSIVE}) {
    display: flex;
    align-items: center;
  }
`;

export const Logo = styled.img`
  width: 196px;
  height: 74px;
`;

export const PersonWelcome = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  font-size: 15px;
  font-weight: normal;
  text-align: right;
  font-family: ${font};
  color: ${headerLinkColor};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  p {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const ExitButton = styled.button`
  border: none;
  background: none;
  font-family: ${font};
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: var(--softBlue);
  cursor: pointer;
`;

export const OpenCloseResponsiveMenu = styled.button`
  display: none;
  border: none;
  background: none;
  width: 45px;
  height: 45px;
  margin-left: 20px;
  svg {
    width: 45px;
    height: 45px;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: ${RESPONSIVE}) {
    display: block;
  }
`;

export const List = styled.ul`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    display: flex;
    ${ExitButton} {
      margin-left: 20px;
    }

    @media (max-width: ${RESPONSIVE}) {
      display: none;
      &.not-hide {
        display: flex;
      }
    }
  }
`;

export const Overlay = styled.div<{ active: boolean }>`
  width: 100%;
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 10;
  display: ${props => (props.active ? 'flex' : 'none')};
  justify-content: center;
  background: rgba(0, 0, 0, 0.55);
  transition: all ease 0.3s;
  overflow: hidden;
`;
export const ResponsiveMenu = styled.div<{ active: boolean }>`
  width: ${props => (props.active ? '30%' : '0')};
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 999999;
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  background: ${secondaryColor};
  transition: all ease 0.3s;
  overflow: hidden;
  ${List} {
    flex-flow: wrap column;
    justify-content: center;
    opacity: ${props => (props.active ? '100%' : '0')};
    transition: all ease 0.5s;
    li {
      display: block;
      margin-bottom: 20px;
      a {
        font-size: 16px;
        &.active {
          font-size: 16px;
        }
      }
    }
    ${ExitButton} {
      margin-left: 0;
    }
  }
  .menu-header {
    width: 100%;
    display: flex;
    justify-content: center;
    ${OpenCloseResponsiveMenu} {
      margin-left: 0;
      margin-top: 40px;
      position: absolute;
      width: 30px;
      height: 30px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  @media (max-width: ${RESPONSIVE_VALUE}) {
    width: ${props => (props.active ? '80%' : '0')};
  }
`;
