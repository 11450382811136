import { mainColor } from './../../config/theme/index';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${mainColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap column;
`;

export const Logo = styled.img`
  max-width: 70%;
  margin-bottom: 20px;
`;

export const Spinner = styled.img`
  max-width: 30%;
  width: 100px;
  height: 100px;
`;
