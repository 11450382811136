import getTheme from '../../util/getTheme';
import { store } from './../../redux/store';

const { theme } = store.getState().user;

export const font = 'Open Sans, sans-serif';

export const mainColor = getTheme(theme, {
  dark: 'var(--midnight)',
  light: '#fff',
});

export const secondaryColor = getTheme(theme, {
  dark: 'var(--darkSlateBlue)',
  light: '#fff',
});

export const errorColor = getTheme(theme, {
  dark: '#e60000',
  light: '#e60000',
});

export const successColor = getTheme(theme, {
  dark: 'var(--brightLightGreen)',
  light: 'var(--brightLightGreen)',
});

export const labelColor = getTheme(theme, {
  dark: 'var(--warmGrey)',
  light: 'var(--warmGrey)',
});

export const inputBorderlColor = getTheme(theme, {
  dark: 'var(--warmGrey)',
  light: 'var(--warmGrey)',
});

export const inputFocusBorderColor = getTheme(theme, {
  dark: '#404040',
  light: '#404040',
});

export const inputPlaceholderColor = getTheme(theme, {
  dark: '#a6a6a6',
  light: '#a6a6a6',
});

export const headerLinkColor = getTheme(theme, {
  dark: 'var(--white)',
  light: 'var(--white)',
});

export const titleColor = getTheme(theme, {
  dark: 'var(--white)',
  light: 'var(--white)',
});

export const textColor = getTheme(theme, {
  dark: 'var(--white)',
  light: 'var(--white)',
});
