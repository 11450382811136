import React from 'react';
import { NavLinkProps } from 'react-router-dom';

import { Container } from './styles';

const CustomLink: React.FC<NavLinkProps> = ({ children, ...props }) => {
  return (
    <Container {...props} activeClassName='active'>
      {children}
    </Container>
  );
};

export default CustomLink;
