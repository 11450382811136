import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Loader from '../../components/Loader';
import Dashboard from './pages/Dashboard';

const Routes: React.FC = () => {
  const { url } = useRouteMatch();

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={`${url}/`}>
          <Dashboard />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
