export const API_URL = process.env.REACT_APP_API_URL as string;

export const TITLE: string = 'Robomind Mktp';

export const RESPONSIVE_VALUE: string = '900px';

const API_KEY_PAGARME_PROD: string = 'ak_live_AChJyOe9jUbTe42Fw4BjK9ZODHCEjO';

const API_KEY_PAGARME_DEV: string = 'ak_test_txpgj3ghEPqEQ7eQO1CqIy7wb2Hdqy';

export const API_KEY: string =
  process.env.NODE_ENV === 'production'
    ? API_KEY_PAGARME_PROD
    : API_KEY_PAGARME_DEV;
