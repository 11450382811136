/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import {
  AssetPerformanceBox,
  AssetPerformanceColumn,
  ChartLoader,
  ChartWrapper,
  Column,
  ComparativeBox,
  ComparativeValueButton,
  ComparativeValueWrapper,
  Container,
  NoAssets,
  ResumeBox,
  Row,
  Title,
} from './styles';

const Dashboard: React.FC = () => {
  return <Container></Container>;
};

export default Dashboard;
