import React from 'react';

import { Container, Spinner } from './styles';
import spinnerWhite from '../../assets/images/spinner-white.svg';
// import  rm_logo  from '../../assets/images/rm_logo.png';

const Loader: React.FC = () => {
  return (
    <Container>
      {/* <Logo src={rm_logo} /> */}
      <Spinner src={spinnerWhite} alt='Carregando...' />;
    </Container>
  );
};

export default Loader;
